import { theme } from "antd";
import { FC, memo, PropsWithChildren, useCallback, useEffect } from "react";

/**
 * ## Antd 主题 token 包裹器
 *
 * **设置 antd token 全局 css 变量**
 */
const ThemeTokenWrapper: FC<PropsWithChildren> = ({ children }) => {
  // ==================== hook ====================
  const { token: antdThemeToken } = theme.useToken();

  // ==================== function ====================
  const transformTokenName = useCallback(() => {
    // 小驼峰转中划线
    const camelCaseToKebabCase = (str: string) =>
      str.replace(/[a-z]([A-Z])/g, (val, v1) =>
        val.replace(v1, `-${v1.toLowerCase()}`)
      );
    const tokenEntries = Object.entries(antdThemeToken);
    tokenEntries.forEach(
      ([key, tokenVal]) =>
        tokenVal &&
        document.body.style.setProperty(
          `--${camelCaseToKebabCase(key)}`,
          tokenVal
        )
    );
  }, [antdThemeToken]);
  // ==================== effect ====================
  useEffect(() => {
    transformTokenName();
  }, [transformTokenName]);

  return <>{children}</>;
};

export default memo(ThemeTokenWrapper);
