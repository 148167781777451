import microApp from "@micro-zoe/micro-app";
import { message } from "antd";
import { memo, useEffect } from "react";

import { MicroappModuleNameEnum } from "@/microapp/microapp-constants/constants";
import microappGlobalDataStore from "@/microapp/microapp-store/globalStore";

function SystemSettingDataNotify() {
  // ==================== function ====================
  const initSystemSettingDataNotify = () => {
    const dataListener = (data: any) => {
      // set 缓存
      localStorage.setItem("systemConfig", JSON.stringify(data));
      microappGlobalDataStore.changeGlobalData(data);
    };

    microApp.addDataListener(
      MicroappModuleNameEnum.MODULE_SYSTEM,
      dataListener,
      true
    );
  };
  const initDefaultConfig = () => {
    const systemConfig = localStorage.getItem("systemConfig");
    if (systemConfig) {
      microappGlobalDataStore.changeGlobalData(JSON.parse(systemConfig));
      message.success("加载系统缓存设置");
    }
  };

  // ==================== effect ====================
  useEffect(() => {
    initDefaultConfig();
    initSystemSettingDataNotify();
  }, []);

  return null;
}

export default memo(SystemSettingDataNotify);
