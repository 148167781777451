import { UserOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { memo } from "react";

import { items } from "./constants";

function AvatarDropdown() {
  return (
    <Dropdown menu={{ items }}>
      <UserOutlined style={{ fontSize: "22px" }} />
    </Dropdown>
  );
}

export default memo(AvatarDropdown);
