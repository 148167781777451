import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PageLoading from "@/components/page-loading";
import microappGlobalDataStore from "@/microapp/microapp-store/globalStore";

/** 路由守卫 */
function AuthGuard() {
  // ==================== hooks ====================
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [loading, setLoading] = useState(false);

  // ==================== effect ====================
  useEffect(() => {
    setLoading(true);
    const { isLogin } = microappGlobalDataStore.data;
    const isLoginPage = pathname === "/login";

    // 是否重定向到登录页面
    const isRedirectLogin = !isLogin && !isLoginPage;
    if (isRedirectLogin) {
      navigate(`/login?redirect=${pathname}`, { replace: true });
    }
    setLoading(false);
  }, [navigate, pathname, search]);
  return <PageLoading loading={loading} />;
}

export default observer(AuthGuard);
