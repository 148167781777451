import "@/common/styles/global.scss";

import microApp from "@micro-zoe/micro-app";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { MICROAPP_MODULE_CONFIG_MAP } from "./microapp/microapp-constants/constants";

microApp.start({
  // "disable-memory-router": true, // 关闭虚拟路由系统
  // "disable-patch-request": true // 关闭对子应用请求的拦截
});
// 预加载全部子应用
microApp.preFetch(
  [...MICROAPP_MODULE_CONFIG_MAP.entries()].map(([, { entry, name }]) => ({
    name,
    url: entry,
  })),
  0,
);
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
