/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import jsxCustomEvent from "@micro-zoe/micro-app/polyfill/jsx-custom-event";
import { useState } from "react";

import PageLoading from "@/components/page-loading";

import { IMicroAppContainerProps } from "./types";
/**
 * ## 微前端子应用容器
 *
 * **容器包裹，监听回调事件**
 */
function MicroAppContainer({ name, url, ...props }: IMicroAppContainerProps) {
  const [loading, setLoading] = useState<boolean>(false);
  /** 挂载前 */
  const onBeforemount = () => {
    console.log("🟡--子应用--挂载", name, url);
    props?.onBeforeshow?.();
    setLoading(true);
  };
  /** 挂载后 */
  const onMounted = () => {
    console.log("🟢--子应用--挂载", name, url);
    props?.onMounted?.();
    setLoading(false);
  };
  /** 报错时 */
  const onError = () => {
    console.log("🔴--子应用--报错", name, url);
    props?.onError?.();
  };

  return (
    <PageLoading loading={loading}>
      <micro-app
        name={name}
        url={url}
        {...props}
        onBeforemount={onBeforemount}
        onMounted={onMounted}
        onError={onError}
      />
    </PageLoading>
  );
}

export default MicroAppContainer;
