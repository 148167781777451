import { memo } from "react";
import { useNavigate } from "react-router-dom";

import { type NavItemsProps } from "../constants";

function MenuItem({ item }: { item: NavItemsProps }) {
  const { label = "-", path = "" } = item;
  const navigate = useNavigate();
  const handleClick = () => navigate(path);
  return (
    <div
      className="line-clamp-1 flex w-full cursor-pointer items-center overflow-hidden rounded px-3 py-2 text-black hover:bg-slate-300"
      onClick={handleClick}
    >
      {label}
    </div>
  );
}
export default memo(MenuItem);
