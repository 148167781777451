import { Button } from "antd";
import { memo } from "react";
import { Link } from "react-router-dom";

function LoginButton() {
  return (
    <Link to="/login">
      <Button>Login</Button>
    </Link>
  );
}

export default memo(LoginButton);
