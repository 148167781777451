import clsx from "clsx";
import { memo } from "react";

import BaseNav from "../base-nav";
import styles from "./index.module.scss";

/** 侧边导航 */
function SideBar({ className, ...props }: JSX.IntrinsicElements["div"]) {
  // render
  return (
    <nav {...props} className={clsx(className, styles.sideNav)}>
      {/* render menu */}
      <BaseNav />
    </nav>
  );
}

export default memo(SideBar);
