import { memo, PropsWithChildren, Suspense } from "react";

import { Header, SideBar } from "@/components/nav";
import PageLoading from "@/components/page-loading";

// import BreadcrumbNav from "../breadcrumb-nav";
import styles from "./index.module.scss";

function DefaultLayout({ children }: PropsWithChildren) {
  return (
    <div className={styles.defalutLayout}>
      <div className={styles.defalutLayoutBg} />
      <Header />
      <div className={styles.content}>
        <div className="hidden md:block">
          <SideBar />
        </div>
        <main className={styles.main}>
          <Suspense fallback={<PageLoading />}>
            <div className={styles.container}>
              {/* <BreadcrumbNav className={styles.breadcrumb} /> */}
              <div className="relative h-full w-full overflow-auto">
                {children}
              </div>
            </div>
          </Suspense>
        </main>
      </div>
    </div>
  );
}

export default memo(DefaultLayout);
