// store
import microApp from "@micro-zoe/micro-app";
import { merge } from "lodash-es";
import { makeAutoObservable, toJS } from "mobx";

import { MicroAppGlobalDataProps } from "@/microapp/microapp-types";
/**
 * 微前端全局数据源
 */
const microappGlobalDataStore = makeAutoObservable({
  data: {
    microAppConfig: {
      theme: {
        componentSize: "large",
        componentDisabled: false,
        primaryColor: "#121212",
        borderRadius: 18,
        isDark: false,
        isCompact: false,
      },
    },
    user: {},
    isLogin: false,
  } as MicroAppGlobalDataProps,
  changeGlobalData: (data: Partial<MicroAppGlobalDataProps>) => {
    const newData = merge(microappGlobalDataStore.data, data);
    microappGlobalDataStore.data = newData;
    microApp.setGlobalData({ ...toJS(newData) }); // 更新子应用 global data
  },
});

export default microappGlobalDataStore;
