import { navItems, type NavItemsProps } from "../constants";
import MenuItem from "./MenuItem";
import SubMenuItem from "./SubMenuItem";

function BaseNav() {
  const renderChild = (items?: NavItemsProps[]) =>
    items?.map((item) => {
      const { children, path = "", label } = item;
      const hasChild = children?.length;
      if (hasChild && children) {
        return (
          <div key={`${path}${label}`}>
            <SubMenuItem headerItem={item}>
              {renderChild(item.children)}
            </SubMenuItem>
          </div>
        );
      }
      return <MenuItem key={`${path}${label}`} item={item} />;
    });
  return (
    <div className="flex flex-col gap-2 px-2 py-4 text-base">
      {renderChild(navItems)}
    </div>
  );
}

export default BaseNav;
