import microApp from "@micro-zoe/micro-app";
import { Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MicroAppComponent from "@/microapp/microapp-components/microapp-container";
import {
  MICROAPP_MODULE_CONFIG_MAP,
  MicroappModuleNameEnum,
} from "@/microapp/microapp-constants/constants";
import { MICRO_ROUTER_PREFIX } from "@/routers";

/**
 * ## 微前端模块 —— app
 *
 * **子应用通过模块化插入**
 */
function AppModule() {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const microAppName = MicroappModuleNameEnum.MODULE_APP;
  // config
  const { entry, name } = MICROAPP_MODULE_CONFIG_MAP.get(microAppName)!;
  const onMounted = useCallback(() => {
    setLoading(false);
    const path = pathname.replace(
      `/${MICRO_ROUTER_PREFIX}/${microAppName}`,
      "",
    );
    microApp.router.push({ name: microAppName, path });
  }, [microAppName, pathname]);
  useEffect(onMounted, [onMounted]);
  return (
    <Spin spinning={loading}>
      <MicroAppComponent name={name} url={entry} onMounted={onMounted} iframe />
    </Spin>
  );
}

export default AppModule;
