import { WaterMark } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import microappGlobalDataStore from "@/microapp/microapp-store/globalStore";

/** 水印组件 */
function WaterMarkWrapper({ children }: PropsWithChildren) {
  if (!microappGlobalDataStore.data.isLogin) {
    return <>{children}</>;
  }
  return (
    <WaterMark
      content={[
        microappGlobalDataStore.data.user.display_name,
        microappGlobalDataStore.data.user.id
      ]}
      style={{
        display: "flex",
        flex: 1
      }}
    >
      {children}
    </WaterMark>
  );
}

export default observer(WaterMarkWrapper);
