import { lazy, memo, ReactNode, Suspense } from "react";
import { Outlet, RouteObject } from "react-router-dom";

import AuthGuard from "@/common/guards/auth-guard";
import DefaultLayout from "@/components/layout";
import WaterMarkWrapper from "@/components/water-mark";
import MicroAppGlobalDataNotify from "@/microapp/microapp-components/setting-data-notify";
import AppModule from "@/microapp/microapp-module/app";
import SystemModule from "@/microapp/microapp-module/system";

import { MicroappModuleNameEnum } from "./microapp/microapp-constants/constants";

// 微前端路由前缀
export const MICRO_ROUTER_PREFIX = "micro";
// 延迟加载
function LazyComponent(params: {
  element: ReactNode;
  defaultLayout?: boolean;
}) {
  const { element, defaultLayout = true } = params;
  return (
    <Suspense>
      {defaultLayout ? <DefaultLayout>{element}</DefaultLayout> : element}
    </Suspense>
  );
}
// 根路由
const RouterRoot = memo(() => (
  <div className="flex flex-1">
    <WaterMarkWrapper>
      <MicroAppGlobalDataNotify />
      <AuthGuard />
      <Outlet />
    </WaterMarkWrapper>
  </div>
));

const Login = lazy(() => import("./pages/login"));
const Welcome = lazy(() => import("./pages/welcome"));
const NotFound = lazy(() => import("./pages/not-found"));
const Application = lazy(() => import("./pages/application"));

const routers: RouteObject[] = [
  {
    element: <RouterRoot />,
    children: [
      {
        path: "/",
        element: <LazyComponent element={<Welcome />} />,
      },
      {
        path: `/${MICRO_ROUTER_PREFIX}/${MicroappModuleNameEnum.MODULE_SYSTEM}/*`,
        element: <LazyComponent element={<SystemModule />} />,
      },
      {
        path: `/${MICRO_ROUTER_PREFIX}/${MicroappModuleNameEnum.MODULE_APP}/*`,
        element: <LazyComponent element={<AppModule />} />,
      },
      {
        path: "/application",
        element: <LazyComponent element={<Application />} />,
      },
      {
        path: "/login",
        element: <LazyComponent element={<Login />} defaultLayout={false} />,
      },
      {
        path: "*",
        element: <LazyComponent element={<NotFound />} />,
      },
    ],
  },
];

export default routers;
