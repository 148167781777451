import { Avatar, Button, MenuProps, Row } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

import microappGlobalDataStore from "@/microapp/microapp-store/globalStore";

const LogOutButton: FC = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    microappGlobalDataStore.changeGlobalData({
      isLogin: false,
      user: undefined,
    });
    navigate("/");
  };
  return (
    <Button block type="primary" onClick={logout} disabled={false}>
      Logout
    </Button>
  );
};

const UserInfoBoard = observer(() => (
  <Row justify="center" align="middle">
    <Avatar>
      {microappGlobalDataStore.data.user?.display_name.slice(0, 1) || "-"}
    </Avatar>
  </Row>
));

export const items: MenuProps["items"] = [
  {
    label: <UserInfoBoard />,
    key: "0",
  },
  {
    label: <Link to="/system/setting">System Setting</Link>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: <LogOutButton />,
    key: "2",
  },
];
