import { Spin } from "antd";
import { memo, PropsWithChildren } from "react";

import styles from "./index.module.scss";

interface PageLoadingProps {
  loading?: boolean;
}

/** 全屏loading */
function PageLoading(props: PropsWithChildren<PageLoadingProps>) {
  const { loading = true, children } = props;
  return (
    <>
      {loading && (
        <div className={styles.pageLoading}>
          <Spin />
          <div>加载中...</div>
        </div>
      )}
      {children}
    </>
  );
}

export default memo(PageLoading);
