import { CaretLeftOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { memo,PropsWithChildren, useState } from "react";

import { type NavItemsProps } from "../constants";

function SubMenuItem({
  headerItem,
  children
}: PropsWithChildren<{ headerItem?: NavItemsProps }>) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div
        className={clsx(
          "line-clamp-1 flex w-full cursor-pointer justify-between overflow-hidden rounded  bg-slate-300 p-2 text-black",
          { "border-black border-b": open }
        )}
        onClick={() => setOpen((val) => !val)}
      >
        <div>{headerItem?.label}</div>
        <div
          className="transition ease-in-out"
          style={{ transform: `rotate(${open ? -90 : 0}deg)` }}
        >
          <CaretLeftOutlined />
        </div>
      </div>
      <div
        className={clsx(
          "overflow-hidden rounded-b p-2",
          { hidden: !open }
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default memo(SubMenuItem);
