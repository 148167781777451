import { Skeleton } from "antd";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ThemeProvider from "./components/theme";
import routers from "./routers";

function App() {
  const router = createBrowserRouter(routers);

  /** 初始化App */
  useEffect(() => {
    console.log("🟢--基座应用--初始化");
  }, []);

  return (
    <ThemeProvider>
      <RouterProvider router={router} fallbackElement={<Skeleton />} />
    </ThemeProvider>
  );
}

export default App;
