import { ConfigProvider, theme as antdTheme } from "antd";
import { ConfigProviderProps } from "antd/es/config-provider";
import { MappingAlgorithm } from "antd/es/config-provider/context";
import { remove } from "lodash-es";
import { observer } from "mobx-react-lite";
import { FC, PropsWithChildren } from "react";

import microappGlobalDataStore from "@/microapp/microapp-store/globalStore";

import ThemeTokenWrapper from "./ThemeTokenWrapper";

/** 全局主题 */
const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    primaryColor, // 颜色
    componentSize, // 大小
    componentDisabled, // 大小
    borderRadius, // 圆角
    isCompact, // 紧凑
    isDark, // 暗色
  } = microappGlobalDataStore.data.microAppConfig.theme;
  const { darkAlgorithm, compactAlgorithm } = antdTheme;
  const algorithm: MappingAlgorithm[] = remove([
    isDark && darkAlgorithm,
    isCompact && compactAlgorithm,
  ]) as MappingAlgorithm[];

  const theme: ConfigProviderProps["theme"] = {
    token: {
      colorPrimary: primaryColor,
      borderRadius,
    },
    algorithm,
  };
  return (
    <ConfigProvider
      componentSize={componentSize}
      theme={theme}
      componentDisabled={componentDisabled}
    >
      <ThemeTokenWrapper>{children}</ThemeTokenWrapper>
    </ConfigProvider>
  );
};

export default observer(ThemeProvider);
