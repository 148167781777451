import { MicroAppConfigProps } from "./types";

/** 微前端 子应用列表 */
export enum MicroappModuleNameEnum {
  GLOBAL = "global",
  MODULE_SYSTEM = "backend-module-system",
  MODULE_APP = "backend-module-app"
}

type MicroappModuleConfigMapType = Map<
  MicroappModuleNameEnum,
  MicroAppConfigProps
>;
/** 微前端 配置列表 */
export const MICROAPP_MODULE_CONFIG_MAP: MicroappModuleConfigMapType = new Map([
  [
    MicroappModuleNameEnum.MODULE_SYSTEM,
    {
      name: MicroappModuleNameEnum.MODULE_SYSTEM,
      // entry: "http://localhost:3000/",
      entry: "https://records-app-backend-module-system.pages.dev/",
      baseroute: "system",
      eventCenter: MicroappModuleNameEnum.MODULE_SYSTEM
    }
  ],
  [
    MicroappModuleNameEnum.MODULE_APP,
    {
      name: MicroappModuleNameEnum.MODULE_APP,
      // entry: "http://localhost:5174/",
      entry: "https://records-app-backend-module-app.pages.dev/",
      baseroute: "app",
      eventCenter: MicroappModuleNameEnum.MODULE_APP
    }
  ]
]);
