import { SmileOutlined } from "@ant-design/icons";

import {
  // MICROAPP_MODULE_CONFIG_MAP,
  MicroappModuleNameEnum
} from "@/microapp/microapp-constants/constants";
// import { MICRO_ROUTER_PREFIX } from "@/routers";
const MICRO_ROUTER_PREFIX = "micro";

export interface NavItemsProps {
  label: string;
  path?: string;
  microAppName?: MicroappModuleNameEnum;
  icon?: React.ReactNode;
  children?: NavItemsProps[];
}

// const getMicroBaseroute = (microAppName: MicroappModuleNameEnum) =>
//   MICROAPP_MODULE_CONFIG_MAP.get(microAppName)?.baseroute;
const systemMicroAppPath = `/${MICRO_ROUTER_PREFIX}/${MicroappModuleNameEnum.MODULE_SYSTEM}`
const appMicroAppPath = `/${MICRO_ROUTER_PREFIX}/${MicroappModuleNameEnum.MODULE_APP}`
export const navItems: NavItemsProps[] = [
  {
    label: "引导页",
    icon: <SmileOutlined />,
    path: "/"
  },
  {
    label: "系统(子应用)",
    icon: <SmileOutlined />,

    children: [
      // {
      //   label: "设置",
      //   icon: <SmileOutlined />,
      //   path: `${systemMicroAppPath}/setting`
      // },
      // {
      //   label: "用户",
      //   icon: <SmileOutlined />,
      //   path: `${systemMicroAppPath}/user`
      // },
      {
        label: "OSS资源",
        icon: <SmileOutlined />,
        path: `${systemMicroAppPath}/oss`
      },
      // {
      //   label: "Lower-code",
      //   icon: <SmileOutlined />,
      //   path: `${systemMicroAppPath}/lower-code-development-platform`
      // }
    ]
  },
  {
    label: "APP(子应用)",
    icon: <SmileOutlined />,
    children: [
      // {
      //   label: "记录",
      //   icon: <SmileOutlined />,
      //   path: `${appMicroAppPath}/record`
      // },
      {
        label: "App 用户",
        icon: <SmileOutlined />,
        path: `${appMicroAppPath}/user`
      },
      {
        label: "反馈",
        icon: <SmileOutlined />,
        path: `${appMicroAppPath}/feedback`
      }
    ]
  },
  // {
  //   label: "微前端应用",
  //   icon: <SmileOutlined />,
  //   path: "/application"
  // },
  // {
  //   label: "监控面板",
  //   icon: <SmileOutlined />,
  //   path: "/dashboard"
  // }
];
export default {};
