import {
  CloseOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import { memo, useState } from "react";

import microappGlobalDataStore from "@/microapp/microapp-store/globalStore";

import BaseNav from "../base-nav";
import AvatarDropdown from "./components/avatar-dropdown";
import LoginButton from "./components/login-button";
import styles from "./index.module.scss";

function MenuButton() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && (
        <>
          <div className="absolute left-0 top-0 z-50 h-screen w-52">
            <div className="h-full w-full rounded-r-xl bg-white shadow">
              <BaseNav />
            </div>
          </div>
          <button
            type="button"
            className="absolute left-0 top-0 z-40 h-screen w-screen bg-black/50 backdrop-blur-xl"
            onClick={() => setIsOpen(false)}
          >
            <p className="opacity-0">mask</p>
          </button>
        </>
      )}
      <button type="button" onClick={() => setIsOpen((val) => !val)}>
        {isOpen ? <CloseOutlined /> : <MenuOutlined />}
      </button>
    </>
  );
}
function Header() {
  const { isLogin } = microappGlobalDataStore.data;
  return (
    <header className={styles.menu}>
      <div className="text-2xl font-semibold">
        <div className="hidden md:block">Records Backend</div>
        <div className="block md:hidden">
          <MenuButton />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <UnorderedListOutlined />
        <QuestionCircleOutlined />
        {isLogin ? <AvatarDropdown /> : <LoginButton />}
      </div>
    </header>
  );
}

export default memo(Header);
